import React from 'react'
import { Link, graphql } from 'gatsby'
import { kebabCase } from 'lodash'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Disqus } from 'gatsby-plugin-disqus'
import GitHubButton from 'react-github-btn'
import DefaultLayout from '../components/layout'
import SEO from '../components/seo'
import Social from '../components/social'

import 'katex/dist/katex.min.css'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const image = post.frontmatter.img
      ? post.frontmatter.img.childImageSharp.resize
      : null
    const disqusConfig = {
      url: `${this.props.location.href}`,
      identifier: post.id,
      title: post.frontmatter.title,
    }

    return (
      <DefaultLayout>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          image={image}
        />
        <div className="clearfix post-content-box">
          <article className="article-page">
            <div className="page-content">
              {post.frontmatter.img && (
                <div className="page-cover-image">
                  <figure>
                    <GatsbyImage
                      image={
                        post.frontmatter.img.childImageSharp.gatsbyImageData
                      }
                      className="page-image"
                      key={
                        post.frontmatter.img.childImageSharp.gatsbyImageData.src
                      }
                      alt=""
                    />
                  </figure>
                </div>
              )}
              <div className="wrap-content">
                <header className="header-page">
                  <h1 className="page-title">{post.frontmatter.title}</h1>
                  <div className="page-date">
                    <span>{post.frontmatter.date}</span>
                  </div>
                </header>
                <div dangerouslySetInnerHTML={{ __html: post.html }} />
                <div className="page-bottom">
                  <GitHubButton href="https://github.com/marcelmedina/PlayGoKids" data-icon="octicon-star" data-size="large" aria-label="Star marcelmedina/PlayGoKids on GitHub">If you were helped, please star this repo, or...</GitHubButton>
                  <a href="https://www.buymeacoffee.com/marcelmedina" target="_blank"><img width={200} src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" /></a>
                  <Social />
                </div>
                <div className="page-footer">
                  <div className="page-tag">
                    {post.frontmatter.tags &&
                      post.frontmatter.tags.map((tag) => (
                        <span key={tag}>
                          <Link className="tag" to={`/tags/${kebabCase(tag)}/`}>
                            # {tag}
                          </Link>
                        </span>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </article>
          <Disqus config={disqusConfig} className="page-disqus" />
        </div>
      </DefaultLayout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "YYYY, MMM DD")
        tags
        img {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              layout: FULL_WIDTH
              formats: [AUTO, AVIF, WEBP]
            )
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
    }
  }
`
